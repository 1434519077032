var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ListBox_1;
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path='../drop-down-base/drop-down-base-model.d.ts'/>
import { Input } from '@syncfusion/ej2-inputs';
import { DropDownBase, dropDownBaseClasses } from '../drop-down-base/drop-down-base';
import { EventHandler, closest, removeClass, addClass, Complex, Property, ChildProperty, L10n } from '@syncfusion/ej2-base';
import { NotifyPropertyChanges, getComponent, Event, extend, detach, attributes } from '@syncfusion/ej2-base';
import { getUniqueID, Browser, formatUnit, isNullOrUndefined, getValue } from '@syncfusion/ej2-base';
import { prepend, append } from '@syncfusion/ej2-base';
import { cssClass, Sortable, moveTo } from '@syncfusion/ej2-lists';
import { Button } from '@syncfusion/ej2-buttons';
import { createSpinner, showSpinner, hideSpinner, getZindexPartial } from '@syncfusion/ej2-popups';
import { DataManager, Query } from '@syncfusion/ej2-data';
const ITEMTEMPLATE_PROPERTY = 'ItemTemplate';
/**
 * Defines the Selection settings of List Box.
 */
export class SelectionSettings extends ChildProperty {
}
__decorate([
    Property('Multiple')
], SelectionSettings.prototype, "mode", void 0);
__decorate([
    Property(false)
], SelectionSettings.prototype, "showCheckbox", void 0);
__decorate([
    Property(false)
], SelectionSettings.prototype, "showSelectAll", void 0);
__decorate([
    Property('Left')
], SelectionSettings.prototype, "checkboxPosition", void 0);
/**
 * Defines the toolbar settings of List Box.
 */
export class ToolbarSettings extends ChildProperty {
}
__decorate([
    Property([])
], ToolbarSettings.prototype, "items", void 0);
__decorate([
    Property('Right')
], ToolbarSettings.prototype, "position", void 0);
/**
 * The ListBox is a graphical user interface component used to display a list of items.
 * Users can select one or more items in the list using a checkbox or by keyboard selection.
 * It supports sorting, grouping, reordering and drag and drop of items.
 * ```html
 * <select id="listbox">
 *      <option value='1'>Badminton</option>
 *      <option value='2'>Basketball</option>
 *      <option value='3'>Cricket</option>
 *      <option value='4'>Football</option>
 *      <option value='5'>Tennis</option>
 * </select>
 * ```
 * ```typescript
 * <script>
 *   var listObj = new ListBox();
 *   listObj.appendTo("#listbox");
 * </script>
 * ```
 */
let ListBox = ListBox_1 = class ListBox extends DropDownBase {
    /**
     * Constructor for creating the ListBox component.
     *
     * @param {ListBoxModel} options - Specifies ListBox model
     * @param {string | HTMLElement} element - Specifies the element.
     */
    constructor(options, element) {
        super(options, element);
        this.isValidKey = false;
        this.isDataSourceUpdate = false;
        this.keyDownStatus = false;
    }
    /**
     * Adds a new item to the popup list. By default, new item appends to the list as the last item,
     * but you can insert based on the index parameter.
     *
     * @param  { Object[] } items - Specifies an array of JSON data or a JSON data.
     * @param { number } itemIndex - Specifies the index to place the newly added item in the popup list.
     * @returns {void}.
     * @private
     */
    addItem(items, itemIndex) {
        super.addItem(items, itemIndex);
    }
    /**
     * Build and render the component.
     *
     * @private
     * @returns {void}
     */
    render() {
        this.inputString = '';
        this.initLoad = true;
        this.isCustomFiltering = false;
        this.initialSelectedOptions = this.value;
        super.render();
        this.renderComplete();
    }
    initWrapper() {
        const hiddenSelect = this.createElement('select', { className: 'e-hidden-select', attrs: { 'multiple': '' } });
        hiddenSelect.style.visibility = 'hidden';
        this.list.classList.add('e-listbox-wrapper');
        if (this.itemTemplate) {
            this.list.classList.add('e-list-template');
        }
        this.list.classList.add('e-wrapper');
        this.list.classList.add('e-lib');
        if (this.element.tagName === 'EJS-LISTBOX') {
            this.element.setAttribute('tabindex', '0');
            if (this.initLoad) {
                this.element.appendChild(this.list);
            }
        }
        else {
            if (this.initLoad) {
                this.element.parentElement.insertBefore(this.list, this.element);
            }
            this.list.insertBefore(this.element, this.list.firstChild);
            this.element.style.display = 'none';
        }
        this.list.insertBefore(hiddenSelect, this.list.firstChild);
        if (this.list.getElementsByClassName('e-list-item')[0]) {
            this.list.getElementsByClassName('e-list-item')[0].classList.remove(dropDownBaseClasses.focus);
        }
        if (this.itemTemplate) {
            this.renderReactTemplates();
        }
        removeClass([this.list], [dropDownBaseClasses.content, dropDownBaseClasses.root]);
        this.validationAttribute(this.element, hiddenSelect);
        this.list.setAttribute('role', 'listbox');
        attributes(this.list, { 'role': 'listbox', 'aria-multiselectable': this.selectionSettings.mode === 'Multiple' ? 'true' : 'false' });
        this.updateSelectionSettings();
    }
    updateSelectionSettings() {
        if (this.selectionSettings.showCheckbox && this.selectionSettings.showSelectAll && this.liCollections.length) {
            const l10nSelect = new L10n(this.getModuleName(), { selectAllText: 'Select All', unSelectAllText: 'Unselect All' }, this.locale);
            this.showSelectAll = true;
            this.selectAllText = l10nSelect.getConstant('selectAllText');
            this.unSelectAllText = l10nSelect.getConstant('unSelectAllText');
            this.popupWrapper = this.list;
            this.checkBoxSelectionModule.checkAllParent = null;
            this.notify('selectAll', {});
        }
    }
    initDraggable() {
        if (this.ulElement) {
            this.ulElement.id = this.element.id + '_parent';
        }
        if (this.allowDragAndDrop) {
            new Sortable(this.ulElement, {
                scope: this.scope,
                itemClass: 'e-list-item',
                dragStart: this.triggerDragStart.bind(this),
                drag: this.triggerDrag.bind(this),
                beforeDrop: this.beforeDragEnd.bind(this),
                drop: this.dragEnd.bind(this),
                placeHolder: () => { return this.createElement('span', { className: 'e-placeholder' }); },
                helper: (e) => {
                    const wrapper = this.list.cloneNode();
                    const ele = e.sender.cloneNode(true);
                    wrapper.appendChild(ele);
                    const refEle = this.getItems()[0];
                    wrapper.style.width = refEle.offsetWidth + 'px';
                    wrapper.style.height = refEle.offsetHeight + 'px';
                    if ((this.value && this.value.length) > 1 && this.isSelected(ele)) {
                        ele.appendChild(this.createElement('span', {
                            className: 'e-list-badge', innerHTML: this.value.length + ''
                        }));
                    }
                    wrapper.style.zIndex = getZindexPartial(this.element) + '';
                    return wrapper;
                }
            });
        }
    }
    updateActionCompleteData(li, item) {
        this.jsonData.push(item);
    }
    initToolbar() {
        const pos = this.toolbarSettings.position;
        const prevScope = this.element.getAttribute('data-value');
        if (this.toolbarSettings.items.length) {
            const toolElem = this.createElement('div', { className: 'e-listbox-tool', attrs: { 'role': 'toolbar' } });
            const wrapper = this.createElement('div', {
                className: 'e-listboxtool-wrapper e-lib e-' + pos.toLowerCase()
            });
            this.list.parentElement.insertBefore(wrapper, this.list);
            wrapper.appendChild(pos === 'Right' ? this.list : toolElem);
            wrapper.appendChild(pos === 'Right' ? toolElem : this.list);
            this.createButtons(toolElem);
            if (!this.element.id) {
                this.element.id = getUniqueID('e-' + this.getModuleName());
            }
            if (this.scope) {
                document.querySelector(this.scope).setAttribute('data-value', this.element.id);
            }
            else {
                this.updateToolBarState();
            }
        }
        const scope = this.element.getAttribute('data-value');
        if (prevScope && scope && (prevScope !== scope)) {
            this.tBListBox = getComponent(document.getElementById(prevScope), this.getModuleName());
            this.tBListBox.updateToolBarState();
        }
        else if (scope) {
            this.tBListBox = getComponent(document.getElementById(scope), this.getModuleName());
            this.tBListBox.updateToolBarState();
        }
    }
    createButtons(toolElem) {
        let btn;
        let ele;
        let title;
        const l10n = new L10n(this.getModuleName(), {
            moveUp: 'Move Up', moveDown: 'Move Down', moveTo: 'Move To',
            moveFrom: 'Move From', moveAllTo: 'Move All To', moveAllFrom: 'Move All From'
        }, this.locale);
        this.toolbarSettings.items.forEach((value) => {
            title = l10n.getConstant(value);
            ele = this.createElement('button', {
                attrs: {
                    'type': 'button',
                    'data-value': value,
                    'title': title,
                    'aria-label': title
                }
            });
            toolElem.appendChild(ele);
            btn = new Button({ iconCss: 'e-icons e-' + value.toLowerCase() }, ele);
            btn.createElement = this.createElement;
        });
    }
    validationAttribute(input, hiddenSelect) {
        super.validationAttribute(input, hiddenSelect);
        hiddenSelect.required = input.required;
        input.required = false;
    }
    setHeight() {
        const ele = this.toolbarSettings.items.length ? this.list.parentElement : this.list;
        ele.style.height = formatUnit(this.height);
        if (this.allowFiltering && this.height.toString().indexOf('%') < 0) {
            addClass([this.list], 'e-filter-list');
        }
        else {
            removeClass([this.list], 'e-filter-list');
        }
    }
    setCssClass() {
        const wrap = this.toolbarSettings.items.length ? this.list.parentElement : this.list;
        if (this.cssClass) {
            addClass([wrap], this.cssClass.split(' '));
        }
        if (this.enableRtl) {
            addClass([this.list], 'e-rtl');
        }
    }
    setEnable() {
        const ele = this.toolbarSettings.items.length ? this.list.parentElement : this.list;
        if (this.enabled) {
            removeClass([ele], cssClass.disabled);
        }
        else {
            addClass([ele], cssClass.disabled);
        }
    }
    showSpinner() {
        if (!this.spinner) {
            this.spinner = this.createElement('div', { className: 'e-listbox-wrapper' });
        }
        this.spinner.style.height = formatUnit(this.height);
        this.element.parentElement.insertBefore(this.spinner, this.element.nextSibling);
        createSpinner({ target: this.spinner }, this.createElement);
        showSpinner(this.spinner);
    }
    hideSpinner() {
        if (this.spinner.querySelector('.e-spinner-pane')) {
            hideSpinner(this.spinner);
        }
        if (this.spinner.parentElement) {
            detach(this.spinner);
        }
    }
    onInput() {
        this.isDataSourceUpdate = false;
        if (this.keyDownStatus) {
            this.isValidKey = true;
        }
        else {
            this.isValidKey = false;
        }
        this.keyDownStatus = false;
        this.refreshClearIcon();
    }
    clearText() {
        this.filterInput.value = '';
        this.refreshClearIcon();
        const event = document.createEvent('KeyboardEvent');
        this.isValidKey = true;
        this.KeyUp(event);
    }
    refreshClearIcon() {
        if (this.filterInput.parentElement.querySelector('.' + listBoxClasses.clearIcon)) {
            const clearElement = this.filterInput.parentElement.querySelector('.' + listBoxClasses.clearIcon);
            clearElement.style.visibility = this.filterInput.value === '' ? 'hidden' : 'visible';
        }
    }
    onActionComplete(ulElement, list, e) {
        let searchEle;
        if (this.allowFiltering && this.list.getElementsByClassName('e-filter-parent')[0]) {
            searchEle = this.list.getElementsByClassName('e-filter-parent')[0].cloneNode(true);
        }
        if (list.length === 0) {
            const noRecElem = ulElement.childNodes[0];
            if (noRecElem) {
                ulElement.removeChild(noRecElem);
            }
        }
        super.onActionComplete(ulElement, list, e);
        if (this.allowFiltering && !isNullOrUndefined(searchEle)) {
            this.list.insertBefore(searchEle, this.list.firstElementChild);
            this.filterParent = this.list.getElementsByClassName('e-filter-parent')[0];
            this.filterWireEvents(searchEle);
        }
        this.initWrapper();
        this.setSelection();
        this.initDraggable();
        this.mainList = this.ulElement;
        if (this.initLoad) {
            this.jsonData = [];
            extend(this.jsonData, list, []);
            this.initToolbarAndStyles();
            this.wireEvents();
            if (this.showCheckbox) {
                this.setCheckboxPosition();
            }
            if (this.allowFiltering) {
                this.setFiltering();
            }
        }
        else {
            if (this.isDataSourceUpdate) {
                this.jsonData = [];
                extend(this.jsonData, list, []);
                this.isDataSourceUpdate = false;
            }
            if (this.allowFiltering) {
                const filterElem = this.list.getElementsByClassName('e-input-filter')[0];
                const txtLength = this.filterInput.value.length;
                filterElem.selectionStart = txtLength;
                filterElem.selectionEnd = txtLength;
                filterElem.focus();
            }
        }
        if (this.toolbarSettings.items.length && this.scope && this.scope.indexOf('#') > -1 && !isNullOrUndefined(e)) {
            const scope = this.scope.replace('#', '');
            const scopedLB = getComponent(document.getElementById(scope), this.getModuleName());
            scopedLB.initToolbar();
        }
        this.initLoad = false;
    }
    initToolbarAndStyles() {
        this.initToolbar();
        this.setCssClass();
        this.setEnable();
        this.setHeight();
    }
    triggerDragStart(args) {
        let badge;
        args = extend(this.getDragArgs(args), { dragSelected: true });
        if (Browser.isIos) {
            this.list.style.overflow = 'hidden';
        }
        this.trigger('dragStart', args, (dragEventArgs) => {
            this.allowDragAll = dragEventArgs.dragSelected;
            if (!this.allowDragAll) {
                badge = this.ulElement.getElementsByClassName('e-list-badge')[0];
                if (badge) {
                    detach(badge);
                }
            }
        });
    }
    triggerDrag(args) {
        let scrollParent;
        let boundRect;
        let scrollMoved = 36;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let event = args.event;
        let wrapper;
        if (args.target && (args.target.classList.contains("e-listbox-wrapper") || args.target.classList.contains("e-list-item")
            || args.target.classList.contains("e-filter-parent") || args.target.classList.contains("e-input-group"))) {
            if (args.target.classList.contains("e-list-item") || args.target.classList.contains("e-filter-parent")
                || args.target.classList.contains("e-input-group")) {
                wrapper = args.target.closest('.e-listbox-wrapper');
            }
            else {
                wrapper = args.target;
            }
            if (this.allowFiltering) {
                scrollParent = wrapper.querySelector('.e-list-parent');
            }
            else {
                scrollParent = wrapper;
            }
            boundRect = scrollParent.getBoundingClientRect();
            if ((boundRect.y + scrollParent.offsetHeight) - (event.pageY + scrollMoved) < 1) {
                scrollParent.scrollTop = scrollParent.scrollTop + 10;
            }
            else if ((event.pageY - scrollMoved) - boundRect.y < 1) {
                scrollParent.scrollTop = scrollParent.scrollTop - 10;
            }
        }
        if (args.target === null) {
            return;
        }
        this.trigger('drag', this.getDragArgs(args));
        const listObj = this.getComponent(args.target);
        if (listObj && listObj.listData.length === 0) {
            const noRecElem = listObj.ulElement.childNodes[0];
            if (noRecElem) {
                listObj.ulElement.removeChild(noRecElem);
            }
        }
    }
    beforeDragEnd(args) {
        this.dragValue = this.getFormattedValue(args.droppedElement.getAttribute('data-value'));
        if (this.value.indexOf(this.dragValue) > -1) {
            args.items = this.getDataByValues(this.value);
        }
        else {
            args.items = this.getDataByValues([this.dragValue]);
        }
        this.trigger('beforeDrop', args);
    }
    dragEnd(args) {
        let listData;
        let liColl;
        let jsonData;
        let droppedData;
        let selectedOptions;
        let sortedData;
        const dropValue = this.getFormattedValue(args.droppedElement.getAttribute('data-value'));
        const listObj = this.getComponent(args.droppedElement);
        const getArgs = this.getDragArgs({ target: args.droppedElement }, true);
        const sourceArgs = { previousData: this.dataSource };
        const destArgs = { previousData: listObj.dataSource };
        let dragArgs = extend({}, getArgs, { target: args.target, source: { previousData: this.dataSource }, previousIndex: args.previousIndex, currentIndex: args.currentIndex });
        if (listObj !== this) {
            const sourceArgs1 = extend(sourceArgs, { currentData: this.listData });
            dragArgs = extend(dragArgs, { source: sourceArgs1, destination: destArgs });
        }
        if (Browser.isIos) {
            this.list.style.overflow = '';
        }
        if (listObj === this) {
            const ul = this.ulElement;
            listData = [].slice.call(this.listData);
            liColl = [].slice.call(this.liCollections);
            jsonData = [].slice.call(this.jsonData);
            sortedData = [].slice.call(this.sortedData);
            const toSortIdx = args.currentIndex;
            let toIdx = args.currentIndex = this.getCurIdx(this, args.currentIndex);
            const rIdx = listData.indexOf(this.getDataByValue(dropValue));
            const jsonIdx = jsonData.indexOf(this.getDataByValue(dropValue));
            const sIdx = sortedData.indexOf(this.getDataByValue(dropValue));
            listData.splice(toIdx, 0, listData.splice(rIdx, 1)[0]);
            sortedData.splice(toSortIdx, 0, sortedData.splice(sIdx, 1)[0]);
            jsonData.splice(toIdx, 0, jsonData.splice(jsonIdx, 1)[0]);
            liColl.splice(toIdx, 0, liColl.splice(rIdx, 1)[0]);
            if (this.allowDragAll) {
                selectedOptions = this.value && Array.prototype.indexOf.call(this.value, dropValue) > -1 ? this.value : [dropValue];
                selectedOptions.forEach((value) => {
                    if (value !== dropValue) {
                        const idx = listData.indexOf(this.getDataByValue(value));
                        const jsonIdx = jsonData.indexOf(this.getDataByValue(value));
                        const sIdx = sortedData.indexOf(this.getDataByValue(value));
                        if (idx > toIdx) {
                            toIdx++;
                        }
                        jsonData.splice(toIdx, 0, jsonData.splice(jsonIdx, 1)[0]);
                        listData.splice(toIdx, 0, listData.splice(idx, 1)[0]);
                        sortedData.splice(toSortIdx, 0, sortedData.splice(sIdx, 1)[0]);
                        liColl.splice(toIdx, 0, liColl.splice(idx, 1)[0]);
                        ul.insertBefore(this.getItems()[this.getIndexByValue(value)], ul.getElementsByClassName('e-placeholder')[0]);
                    }
                });
            }
            this.listData = listData;
            this.jsonData = jsonData;
            this.sortedData = sortedData;
            this.liCollections = liColl;
        }
        else {
            let li;
            const fLiColl = [].slice.call(this.liCollections);
            let currIdx = args.currentIndex = this.getCurIdx(listObj, args.currentIndex);
            const ul = listObj.ulElement;
            listData = [].slice.call(listObj.listData);
            liColl = [].slice.call(listObj.liCollections);
            jsonData = [].slice.call(listObj.jsonData);
            sortedData = [].slice.call(listObj.sortedData);
            selectedOptions = (this.value && Array.prototype.indexOf.call(this.value, dropValue) > -1 && this.allowDragAll)
                ? this.value : [dropValue];
            const fListData = [].slice.call(this.listData);
            const fSortData = [].slice.call(this.sortedData);
            selectedOptions.forEach((value, index) => {
                droppedData = this.getDataByValue(value);
                const srcIdx = this.listData.indexOf(droppedData);
                const jsonSrcIdx = this.jsonData.indexOf(droppedData);
                const sortIdx = this.sortedData.indexOf(droppedData);
                fListData.splice(srcIdx, 1);
                this.jsonData.splice(jsonSrcIdx, 1);
                fSortData.splice(sortIdx, 1);
                this.listData = fListData;
                this.sortedData = fSortData;
                const destIdx = value === dropValue ? args.currentIndex : currIdx;
                listData.splice(destIdx, 0, droppedData);
                jsonData.splice(destIdx, 0, droppedData);
                sortedData.splice(destIdx, 0, droppedData);
                liColl.splice(destIdx, 0, fLiColl.splice(srcIdx, 1)[0]);
                if (!value) {
                    const liCollElem = this.getItems();
                    for (let i = 0; i < liCollElem.length; i++) {
                        if (liCollElem[i].getAttribute('data-value') === null && liCollElem[i].classList.contains('e-list-item')) {
                            li = liCollElem[i];
                            break;
                        }
                    }
                }
                else {
                    li = this.getItems()[this.getIndexByValue(value)];
                }
                if (!li) {
                    li = args.helper;
                }
                this.removeSelected(this, value === dropValue ? [args.droppedElement] : [li]);
                ul.insertBefore(li, ul.getElementsByClassName('e-placeholder')[0]);
                currIdx++;
            });
            if (this.fields.groupBy) {
                let sourceElem = this.renderItems(this.listData, this.fields);
                this.updateListItems(sourceElem, this.ulElement);
                this.setSelection();
            }
            if (listObj.sortOrder !== 'None' || this.selectionSettings.showCheckbox
                !== listObj.selectionSettings.showCheckbox || listObj.fields.groupBy || listObj.itemTemplate || this.itemTemplate) {
                const sortable = getComponent(ul, 'sortable');
                let sourceElem = listObj.renderItems(listData, listObj.fields);
                listObj.updateListItems(sourceElem, ul);
                this.setSelection();
                if (sortable.placeHolderElement) {
                    ul.appendChild(sortable.placeHolderElement);
                }
                ul.appendChild(args.helper);
                listObj.setSelection();
            }
            this.liCollections = fLiColl;
            listObj.liCollections = liColl;
            listObj.jsonData = extend([], [], jsonData, false);
            listObj.listData = extend([], [], listData, false);
            listObj.sortedData = extend([], [], sortedData, false);
            if (this.listData.length === 0) {
                this.l10nUpdate();
            }
        }
        if (this === listObj) {
            const sourceArgs1 = extend(sourceArgs, { currentData: listData });
            dragArgs = extend(dragArgs, { source: sourceArgs1 });
        }
        else {
            const dragArgs1 = extend(destArgs, { currentData: listData });
            dragArgs = extend(dragArgs, { destination: dragArgs1 });
        }
        this.trigger('drop', dragArgs);
    }
    updateListItems(sourceElem, destElem) {
        const i = 0;
        destElem.innerHTML = "";
        while (i < sourceElem.childNodes.length) {
            destElem.appendChild(sourceElem.childNodes[i]);
        }
    }
    removeSelected(listObj, elems) {
        if (listObj.selectionSettings.showCheckbox) {
            elems.forEach((ele) => { ele.getElementsByClassName('e-frame')[0].classList.remove('e-check'); });
        }
        else {
            removeClass(elems, cssClass.selected);
        }
    }
    getCurIdx(listObj, idx) {
        if (listObj.fields.groupBy) {
            idx -= [].slice.call(listObj.ulElement.children).slice(0, idx)
                .filter((ele) => ele.classList.contains(cssClass.group)).length;
        }
        return idx;
    }
    getComponent(li) {
        let listObj;
        const ele = (this.element.tagName === 'EJS-LISTBOX' ? closest(li, '.e-listbox')
            : closest(li, '.e-listbox-wrapper') && closest(li, '.e-listbox-wrapper').querySelector('.e-listbox'));
        if (ele) {
            listObj = getComponent(ele, this.getModuleName());
        }
        return listObj;
    }
    listOption(dataSource, fields) {
        this.listCurrentOptions = super.listOption(dataSource, fields);
        this.listCurrentOptions = extend({}, this.listCurrentOptions, { itemCreated: this.triggerBeforeItemRender.bind(this) }, true);
        this.notify('listoption', { module: 'CheckBoxSelection' });
        return this.listCurrentOptions;
    }
    triggerBeforeItemRender(e) {
        e.item.setAttribute('tabindex', '-1');
        this.trigger('beforeItemRender', { element: e.item, item: e.curData });
    }
    requiredModules() {
        const modules = [];
        if (this.selectionSettings.showCheckbox) {
            modules.push({
                member: 'CheckBoxSelection',
                args: [this]
            });
        }
        return modules;
    }
    /**
     * This method is used to enable or disable the items in the ListBox based on the items and enable argument.
     *
     * @param {string[]} items - Text items that needs to be enabled/disabled.
     * @param {boolean} enable - Set `true`/`false` to enable/disable the list items.
     * @param {boolean} isValue - Set `true` if `items` parameter is a array of unique values.
     * @returns {void}
     */
    enableItems(items, enable = true, isValue) {
        let li;
        items.forEach((item) => {
            let text = item;
            li = this.findListElement(this.list, 'li', 'data-value', isValue ? text : this.getValueByText(text));
            if (!li) {
                return;
            }
            if (enable) {
                removeClass([li], cssClass.disabled);
                li.removeAttribute('aria-disabled');
            }
            else {
                addClass([li], cssClass.disabled);
                li.setAttribute('aria-disabled', 'true');
            }
        });
    }
    /**
     * Based on the state parameter, specified list item will be selected/deselected.
     *
     * @param {string[]} items - Array of text value of the item.
     * @param {boolean} state - Set `true`/`false` to select/un select the list items.
     * @param {boolean} isValue - Set `true` if `items` parameter is a array of unique values.
     * @returns {void}
     */
    selectItems(items, state = true, isValue) {
        this.setSelection(items, state, !isValue);
        this.updateSelectedOptions();
    }
    /**
     * Based on the state parameter, entire list item will be selected/deselected.
     *
     * @param {boolean} state - Set `true`/`false` to select/un select the entire list items.
     * @returns {void}
     */
    selectAll(state = true) {
        this.selectAllItems(state);
    }
    /**
     * Adds a new item to the list. By default, new item appends to the list as the last item,
     * but you can insert based on the index parameter.
     *
     * @param  { Object[] } items - Specifies an array of JSON data or a JSON data.
     * @param { number } itemIndex - Specifies the index to place the newly added item in the list.
     * @returns {void}.
     */
    addItems(items, itemIndex) {
        super.addItem(items, itemIndex);
    }
    /**
     * Removes a item from the list. By default, removed the last item in the list,
     * but you can remove based on the index parameter.
     *
     * @param  { Object[] } items - Specifies an array of JSON data or a JSON data.
     * @param { number } itemIndex - Specifies the index to remove the item from the list.
     * @returns {void}.
     */
    removeItems(items, itemIndex) {
        this.removeItem(items, itemIndex);
    }
    /**
     * Removes a item from the list. By default, removed the last item in the list,
     * but you can remove based on the index parameter.
     *
     * @param  { Object[] } items - Specifies an array of JSON data or a JSON data.
     * @param { number } itemIndex - Specifies the index to remove the item from the list.
     * @returns {void}.
     */
    removeItem(items, itemIndex) {
        const liCollections = [];
        const liElement = this.list.querySelectorAll('.' + dropDownBaseClasses.li);
        if (items) {
            items = (items instanceof Array ? items : [items]);
            const fields = this.fields;
            let dataValue;
            let objValue;
            const dupData = [];
            let itemIdx;
            extend(dupData, [], this.listData);
            const removeIdxes = [];
            const removeLiIdxes = [];
            for (let j = 0; j < items.length; j++) {
                if (items[j] instanceof Object) {
                    dataValue = getValue(fields.value, items[j]);
                }
                else {
                    dataValue = items[j].toString();
                }
                for (let i = 0, len = dupData.length; i < len; i++) {
                    if (dupData[i] instanceof Object) {
                        objValue = getValue(fields.value, dupData[i]);
                    }
                    else {
                        objValue = dupData[i].toString();
                    }
                    if (objValue === dataValue) {
                        itemIdx = this.getIndexByValue(dataValue);
                        const idx = itemIdx === i ? itemIdx : i;
                        liCollections.push(liElement[idx]);
                        removeIdxes.push(idx);
                        removeLiIdxes.push(idx);
                    }
                }
            }
            for (let k = removeIdxes.length - 1; k >= 0; k--) {
                this.listData.splice(removeIdxes[k], 1);
            }
            for (let k = removeIdxes.length - 1; k >= 0; k--) {
                this.jsonData.splice(removeIdxes[k], 1);
            }
            for (let k = removeLiIdxes.length - 1; k >= 0; k--) {
                this.updateLiCollection(removeLiIdxes[k]);
            }
        }
        else {
            itemIndex = itemIndex ? itemIndex : 0;
            liCollections.push(liElement[itemIndex]);
            this.listData.splice(itemIndex, 1);
            this.jsonData.splice(itemIndex, 1);
            this.updateLiCollection(itemIndex);
        }
        for (let i = 0; i < liCollections.length; i++) {
            this.ulElement.removeChild(liCollections[i]);
        }
        if (this.listData.length === 0) {
            this.l10nUpdate();
        }
        this.value = null;
        this.updateToolBarState();
    }
    /**
     * Gets the array of data Object that matches the given array of values.
     *
     * @param  { string[] | number[] | boolean[] } value - Specifies the array value of the list item.
     * @returns {object[]}.
     */
    getDataByValues(value) {
        const data = [];
        for (let i = 0; i < value.length; i++) {
            data.push(this.getDataByValue(value[i]));
        }
        return data;
    }
    /**
     * Moves the given value(s) / selected value(s) upwards.
     *
     * @param  { string[] | number[] | boolean[] } value - Specifies the value(s).
     * @returns {void}
     */
    moveUp(value) {
        const elem = (value) ? this.getElemByValue(value) : this.getSelectedItems();
        this.moveUpDown(true, false, elem);
    }
    /**
     * Moves the given value(s) / selected value(s) downwards.
     *
     * @param  { string[] | number[] | boolean[] } value - Specifies the value(s).
     * @returns {void}
     */
    moveDown(value) {
        const elem = (value) ? this.getElemByValue(value) : this.getSelectedItems();
        this.moveUpDown(false, false, elem);
    }
    /**
     * Moves the given value(s) / selected value(s) to the given / default scoped ListBox.
     *
     * @param  { string[] | number[] | boolean[] } value - Specifies the value or array value of the list item.
     * @param {number} index - Specifies the index.
     * @param {string} targetId - Specifies the target id.
     * @returns {void}
     */
    moveTo(value, index, targetId) {
        const elem = (value) ? this.getElemByValue(value) : this.getSelectedItems();
        const tlistbox = (targetId) ? getComponent(targetId, ListBox_1) : this.getScopedListBox();
        this.moveData(this, tlistbox, false, elem, index);
    }
    /**
     * Moves all the values from one ListBox to the scoped ListBox.
     *
     * @param  { string } targetId - Specifies the scoped ListBox ID.
     * @param  { string } index - Specifies the index to where the items moved.
     * @returns {void}
     */
    moveAllTo(targetId, index) {
        if (this.listData.length > 0) {
            const tlistbox = (targetId) ? getComponent(targetId, ListBox_1) : this.getScopedListBox();
            this.moveAllData(this, tlistbox, false, index);
        }
    }
    /* eslint-disable */
    /**
     * Gets the updated dataSource in ListBox.
     *
     * @returns {{ [key: string]: Object }[] | string[] | boolean[] | number[]} - Updated DataSource.
     */
    /* eslint-enable */
    getDataList() {
        return this.jsonData;
    }
    /* eslint-disable */
    /**
     * Returns the sorted Data in ListBox.
     *
     * @returns {{ [key: string]: Object }[] | string[] | boolean[] | number[]} - Sorted data
     */
    /* eslint-enable */
    getSortedList() {
        let sortData;
        let tempData;
        sortData = tempData = this.sortedData;
        if (this.fields.groupBy) {
            sortData = [];
            for (let i = 0; i < tempData.length; i++) {
                if (tempData[i].isHeader) {
                    continue;
                }
                sortData.push(tempData[i]);
            }
        }
        return sortData;
    }
    getElemByValue(value) {
        const elem = [];
        for (let i = 0; i < value.length; i++) {
            elem.push(this.ulElement.querySelector('[data-value ="' + value[i] + '"]'));
        }
        return elem;
    }
    updateLiCollection(index) {
        const tempLi = [].slice.call(this.liCollections);
        tempLi.splice(index, 1);
        this.liCollections = tempLi;
    }
    selectAllItems(state, event) {
        [].slice.call(this.getItems()).forEach((li) => {
            if (!li.classList.contains(cssClass.disabled)) {
                if (this.selectionSettings.showCheckbox) {
                    const ele = li.getElementsByClassName('e-check')[0];
                    if ((!ele && state) || (ele && !state)) {
                        this.notify('updatelist', { li: li, module: 'listbox' });
                        if (this.maximumSelectionLength >= this.list.querySelectorAll('.e-list-item span.e-check').length) {
                            this.checkMaxSelection();
                        }
                    }
                }
                else {
                    if (state) {
                        li.classList.add(cssClass.selected);
                    }
                    else {
                        li.classList.remove(cssClass.selected);
                    }
                }
            }
        });
        this.updateSelectedOptions();
        if (this.allowFiltering && this.selectionSettings.showCheckbox) {
            const liEle = this.list.getElementsByTagName('li');
            let index = 0;
            if (state) {
                for (index = 0; index < liEle.length; index++) {
                    const dataValue1 = this.getFormattedValue(liEle[index].getAttribute('data-value'));
                    if (!this.value.some((e) => e === dataValue1)) {
                        this.value.push(this.getFormattedValue(liEle[index].getAttribute('data-value')));
                    }
                }
            }
            else {
                for (index = 0; index < liEle.length; index++) {
                    const dataValue2 = this.getFormattedValue(liEle[index].getAttribute('data-value'));
                    this.value = this.value.filter((e) => e !== dataValue2);
                }
            }
            if (document.querySelectorAll('ul').length < 2) {
                this.updateMainList();
            }
        }
        this.triggerChange(this.getSelectedItems(), event);
    }
    updateMainList() {
        const mainList = this.mainList.querySelectorAll('.e-list-item');
        const ulList = this.ulElement.querySelectorAll('.e-list-item');
        const mainCount = mainList.length;
        const ulEleCount = ulList.length;
        if (this.selectionSettings.showCheckbox || (document.querySelectorAll('ul').length > 1 || mainCount !== ulEleCount)) {
            let listindex = 0;
            let valueindex = 0;
            let count = 0;
            for (listindex; listindex < mainCount;) {
                if (this.value) {
                    for (valueindex; valueindex < this.value.length; valueindex++) {
                        if (mainList[listindex].getAttribute('data-value') === this.value[valueindex]) {
                            count++;
                        }
                    }
                }
                if (!count && this.selectionSettings.showCheckbox) {
                    mainList[listindex].getElementsByClassName('e-frame')[0].classList.remove('e-check');
                }
                if (document.querySelectorAll('ul').length > 1 && count && mainCount !== ulEleCount) {
                    this.mainList.removeChild(this.mainList.getElementsByTagName('li')[listindex]);
                    listindex = 0;
                }
                else {
                    listindex++;
                }
                count = 0;
                valueindex = 0;
            }
        }
    }
    wireEvents() {
        const form = closest(this.element, 'form');
        const wrapper = this.element.tagName === 'EJS-LISTBOX' ? this.element : this.list;
        EventHandler.add(this.list, 'click', this.clickHandler, this);
        EventHandler.add(wrapper, 'keydown', this.keyDownHandler, this);
        EventHandler.add(wrapper, 'focusout', this.focusOutHandler, this);
        this.wireToolbarEvent();
        if (this.selectionSettings.showCheckbox) {
            EventHandler.remove(document, 'mousedown', this.checkBoxSelectionModule.onDocumentClick);
        }
        if (this.fields.groupBy || this.element.querySelector('select>optgroup')) {
            EventHandler.remove(this.list, 'scroll', this.setFloatingHeader);
        }
        if (form) {
            EventHandler.add(form, 'reset', this.formResetHandler, this);
        }
    }
    wireToolbarEvent() {
        if (this.toolbarSettings.items.length) {
            EventHandler.add(this.getToolElem(), 'click', this.toolbarClickHandler, this);
        }
    }
    unwireEvents() {
        const form = closest(this.element, 'form');
        const wrapper = this.element.tagName === 'EJS-LISTBOX' ? this.element : this.list;
        EventHandler.remove(this.list, 'click', this.clickHandler);
        EventHandler.remove(wrapper, 'keydown', this.keyDownHandler);
        EventHandler.remove(wrapper, 'focusout', this.focusOutHandler);
        if (this.allowFiltering && this.clearFilterIconElem) {
            EventHandler.remove(this.clearFilterIconElem, 'click', this.clearText);
        }
        if (this.toolbarSettings.items.length) {
            EventHandler.remove(this.getToolElem(), 'click', this.toolbarClickHandler);
        }
        if (form) {
            EventHandler.remove(form, 'reset', this.formResetHandler);
        }
    }
    clickHandler(e) {
        this.selectHandler(e);
    }
    checkSelectAll() {
        let searchCount = 0;
        const liItems = this.list.querySelectorAll('li.' + dropDownBaseClasses.li);
        for (let i = 0; i < liItems.length; i++) {
            if (!liItems[i].classList.contains('e-disabled')) {
                searchCount++;
            }
        }
        const len = this.getSelectedItems().length;
        if (this.showSelectAll && searchCount) {
            this.notify('checkSelectAll', { module: 'CheckBoxSelection',
                value: (searchCount === len) ? 'check' : (len === 0) ? 'uncheck' : 'indeterminate' });
        }
    }
    getQuery(query) {
        let filterQuery = query ? query.clone() : this.query ? this.query.clone() : new Query();
        if (this.allowFiltering) {
            const filterType = this.inputString === '' ? 'contains' : this.filterType;
            let dataType = this.typeOfData(this.dataSource).typeof;
            if (dataType === null) {
                dataType = this.typeOfData(this.jsonData).typeof;
            }
            if (!(this.dataSource instanceof DataManager) && dataType === 'string' || dataType === 'number') {
                filterQuery.where('', filterType, this.inputString, this.ignoreCase, this.ignoreAccent);
            }
            else {
                const fields = (this.fields.text) ? this.fields.text : '';
                filterQuery.where(fields, filterType, this.inputString, this.ignoreCase, this.ignoreAccent);
            }
        }
        else {
            filterQuery = query ? query : this.query ? this.query : new Query();
        }
        return filterQuery;
    }
    setFiltering() {
        let filterInputObj;
        if (this.initLoad || isNullOrUndefined(this.filterParent)) {
            this.filterParent = this.createElement('span', {
                className: listBoxClasses.filterParent
            });
            this.filterInput = this.createElement('input', {
                attrs: { type: 'text' },
                className: listBoxClasses.filterInput
            });
            this.element.parentNode.insertBefore(this.filterInput, this.element);
            filterInputObj = Input.createInput({
                element: this.filterInput,
                buttons: [listBoxClasses.filterBarClearIcon],
                properties: { placeholder: this.filterBarPlaceholder }
            }, this.createElement);
            append([filterInputObj.container], this.filterParent);
            prepend([this.filterParent], this.list);
            attributes(this.filterInput, {
                'aria-disabled': 'false',
                'aria-owns': this.element.id + '_options',
                'role': 'listbox',
                'aria-activedescendant': null,
                'autocomplete': 'off',
                'autocorrect': 'off',
                'autocapitalize': 'off',
                'spellcheck': 'false'
            });
            if (this.height.toString().indexOf('%') < 0) {
                addClass([this.list], 'e-filter-list');
            }
            this.inputString = this.filterInput.value;
            this.filterWireEvents();
            return filterInputObj;
        }
    }
    filterWireEvents(filterElem) {
        if (filterElem) {
            this.filterInput = filterElem.querySelector('.e-input-filter');
        }
        this.clearFilterIconElem = this.filterInput.parentElement.querySelector('.' + listBoxClasses.clearIcon);
        if (this.clearFilterIconElem) {
            EventHandler.add(this.clearFilterIconElem, 'click', this.clearText, this);
            if (!filterElem) {
                this.clearFilterIconElem.style.visibility = 'hidden';
            }
        }
        EventHandler.add(this.filterInput, 'input', this.onInput, this);
        EventHandler.add(this.filterInput, 'keyup', this.KeyUp, this);
        EventHandler.add(this.filterInput, 'keydown', this.onKeyDown, this);
    }
    selectHandler(e, isKey) {
        let isSelect = true;
        let currSelIdx;
        const li = closest(e.target, '.' + 'e-list-item');
        let selectedLi = [li];
        if (li && li.parentElement) {
            currSelIdx = [].slice.call(li.parentElement.children).indexOf(li);
            if (!this.selectionSettings.showCheckbox) {
                if ((e.ctrlKey || Browser.isDevice) && this.isSelected(li)) {
                    li.classList.remove(cssClass.selected);
                    li.removeAttribute('aria-selected');
                    isSelect = false;
                }
                else if (!(this.selectionSettings.mode === 'Multiple' && (e.ctrlKey || Browser.isDevice))) {
                    this.getSelectedItems().forEach((ele) => {
                        ele.removeAttribute('aria-selected');
                    });
                    removeClass(this.getSelectedItems(), cssClass.selected);
                }
            }
            else {
                isSelect = !li.getElementsByClassName('e-frame')[0].classList.contains('e-check');
            }
            if (e.shiftKey && !this.selectionSettings.showCheckbox && this.selectionSettings.mode !== 'Single') {
                selectedLi = [].slice.call(li.parentElement.children)
                    .slice(Math.min(currSelIdx, this.prevSelIdx), Math.max(currSelIdx, this.prevSelIdx) + 1)
                    .filter((ele) => { return ele.classList.contains('e-list-item'); });
            }
            else {
                this.prevSelIdx = [].slice.call(li.parentElement.children).indexOf(li);
            }
            if (isSelect) {
                if (!this.selectionSettings.showCheckbox) {
                    addClass(selectedLi, cssClass.selected);
                }
                selectedLi.forEach((ele) => {
                    ele.setAttribute('aria-selected', 'true');
                });
                this.list.setAttribute('aria-activedescendant', li.id);
            }
            else {
                selectedLi.forEach((ele) => {
                    ele.setAttribute('aria-selected', 'false');
                });
            }
            if (!isKey && (this.maximumSelectionLength > (this.value && this.value.length) || !isSelect) &&
                (this.maximumSelectionLength >= (this.value && this.value.length) || !isSelect) &&
                !(this.maximumSelectionLength < (this.value && this.value.length))) {
                this.notify('updatelist', { li: li, e: e, module: 'listbox' });
            }
            if (this.allowFiltering && !isKey) {
                const liDataValue = this.getFormattedValue(li.getAttribute('data-value'));
                if (!isSelect) {
                    this.value = this.value.filter((value1) => value1 !== liDataValue);
                }
                else {
                    const values = [];
                    extend(values, this.value);
                    values.push(liDataValue);
                    this.value = values;
                }
                if (document.querySelectorAll('ul').length < 2) {
                    this.updateMainList();
                }
            }
            this.updateSelectedOptions();
            this.triggerChange(this.getSelectedItems(), e);
            this.checkMaxSelection();
        }
    }
    triggerChange(selectedLis, event) {
        this.trigger('change', { elements: selectedLis, items: this.getDataByElements(selectedLis), value: this.value, event: event });
    }
    getDataByElems(elems) {
        const data = [];
        for (let i = 0, len = elems.length; i < len; i++) {
            data.push(this.getDataByValue(this.getFormattedValue(elems[i].getAttribute('data-value'))));
        }
        return data;
    }
    getDataByElements(elems) {
        const data = [];
        let value;
        let sIdx = 0;
        if (!isNullOrUndefined(this.listData)) {
            const type = this.typeOfData(this.listData).typeof;
            if (type === 'string' || type === 'number' || type === 'boolean') {
                for (const item of this.listData) {
                    for (let i = sIdx, len = elems.length; i < len; i++) {
                        value = this.getFormattedValue(elems[i].getAttribute('data-value'));
                        if (!isNullOrUndefined(item) && item === value) {
                            sIdx = i;
                            data.push(item);
                            break;
                        }
                    }
                    if (elems.length === data.length) {
                        break;
                    }
                }
            }
            else {
                for (const item of this.listData) {
                    for (let i = sIdx, len = elems.length; i < len; i++) {
                        value = this.getFormattedValue(elems[i].getAttribute('data-value'));
                        if (!isNullOrUndefined(item) && getValue((this.fields.value ? this.fields.value : 'value'), item) === value) {
                            sIdx = i;
                            data.push(item);
                            break;
                        }
                    }
                    if (elems.length === data.length) {
                        break;
                    }
                }
            }
            return data;
        }
        return null;
    }
    checkMaxSelection() {
        const limit = this.list.querySelectorAll('.e-list-item span.e-check').length;
        if (this.selectionSettings.showCheckbox) {
            let index = 0;
            const liCollElem = this.list.getElementsByClassName('e-list-item');
            for (index; index < liCollElem.length; index++) {
                if (!liCollElem[index].querySelector('.e-frame.e-check')) {
                    if (limit === this.maximumSelectionLength) {
                        liCollElem[index].classList.add('e-disable');
                    }
                    else if (liCollElem[index].classList.contains('e-disable')) {
                        liCollElem[index].classList.remove('e-disable');
                    }
                }
            }
        }
    }
    toolbarClickHandler(e) {
        const btn = closest(e.target, 'button');
        if (btn) {
            this.toolbarAction = btn.getAttribute('data-value');
            if (btn.disabled) {
                return;
            }
            switch (this.toolbarAction) {
                case 'moveUp':
                    this.moveUpDown(true);
                    break;
                case 'moveDown':
                    this.moveUpDown();
                    break;
                case 'moveTo':
                    this.moveItemTo();
                    break;
                case 'moveFrom':
                    this.moveItemFrom();
                    break;
                case 'moveAllTo':
                    this.moveAllItemTo();
                    break;
                case 'moveAllFrom':
                    this.moveAllItemFrom();
                    break;
                default:
                    this.trigger('actionBegin', { cancel: false, items: this.getDataByElems(this.getSelectedItems()),
                        eventName: this.toolbarAction });
                    break;
            }
        }
    }
    moveUpDown(isUp, isKey, value) {
        let elems = this.getSelectedItems();
        if (value) {
            elems = value;
        }
        if (((isUp && this.isSelected(this.ulElement.firstElementChild))
            || (!isUp && this.isSelected(this.ulElement.lastElementChild))) && !value) {
            return;
        }
        const tempItems = this.getDataByElems(elems);
        const localDataArgs = { cancel: false, items: tempItems, eventName: this.toolbarAction };
        this.trigger('actionBegin', localDataArgs);
        if (localDataArgs.cancel) {
            return;
        }
        (isUp ? elems : elems.reverse()).forEach((ele) => {
            const jsonToIdx = Array.prototype.indexOf.call(this.ulElement.querySelectorAll('.e-list-item'), ele);
            const idx = Array.prototype.indexOf.call(this.ulElement.children, ele);
            moveTo(this.ulElement, this.ulElement, [idx], isUp ? idx - 1 : idx + 2);
            this.changeData(idx, isUp ? idx - 1 : idx + 1, isUp ? jsonToIdx - 1 : jsonToIdx + 1, ele);
        });
        this.trigger('actionComplete', { items: tempItems, eventName: this.toolbarAction });
        elems[0].focus();
        if (!isKey && this.toolbarSettings.items.length) {
            this.getToolElem().querySelector('[data-value=' + (isUp ? 'moveUp' : 'moveDown') + ']').focus();
        }
        this.updateToolBarState();
    }
    moveItemTo() {
        this.moveData(this, this.getScopedListBox());
    }
    moveItemFrom() {
        this.moveData(this.getScopedListBox(), this);
    }
    /**
     * Called internally if any of the property value changed.
     *
     * @param {ListBox} fListBox - Specifies the from listbox.
     * @param {ListBox} tListBox - Specifies the to listbox.
     * @param {boolean} isKey - Specifies the key.
     * @param {Element[]} value - Specifies the value.
     * @param {number} index - Specifies the index.
     * @returns {void}
     * @private
     */
    moveData(fListBox, tListBox, isKey, value, index) {
        const idx = [];
        const dataIdx = [];
        const jsonIdx = [];
        const sortIdx = [];
        const listData = [].slice.call(fListBox.listData);
        const tListData = [].slice.call(tListBox.listData);
        const sortData = [].slice.call(fListBox.sortedData);
        let tSortData = [].slice.call(tListBox.sortedData);
        const fliCollections = [].slice.call(fListBox.liCollections);
        const dataLiIdx = [];
        const tliCollections = [].slice.call(tListBox.liCollections);
        const tempItems = [];
        const data = [];
        let elems = fListBox.getSelectedItems();
        if (value) {
            elems = value;
        }
        const isRefresh = tListBox.sortOrder !== 'None' || (tListBox.selectionSettings.showCheckbox !==
            fListBox.selectionSettings.showCheckbox) || tListBox.fields.groupBy || tListBox.itemTemplate || fListBox.itemTemplate;
        fListBox.value = [];
        if (elems.length) {
            this.removeSelected(fListBox, elems);
            elems.forEach((ele) => {
                idx.push(Array.prototype.indexOf.call(fListBox.ulElement.children, ele)); // update sortable elem
                // To update lb view data
                dataLiIdx.push(Array.prototype.indexOf.call(fListBox.ulElement.querySelectorAll('.e-list-item'), ele));
                // To update lb listdata data
                dataIdx.push(Array.prototype.indexOf.call(fListBox.listData, fListBox.getDataByElems([ele])[0]));
                // To update lb sorted data
                sortIdx.push(Array.prototype.indexOf.call(fListBox.sortedData, fListBox.getDataByElems([ele])[0]));
                // To update lb original data
                jsonIdx.push(Array.prototype.indexOf.call(fListBox.jsonData, fListBox.getDataByElems([ele])[0]));
            });
            if (this.sortOrder !== 'None') {
                sortIdx.forEach((i) => {
                    tempItems.push(fListBox.sortedData[i]);
                });
            }
            else {
                jsonIdx.forEach((i) => {
                    tempItems.push(fListBox.jsonData[i]);
                });
            }
            const localDataArgs = { cancel: false, items: tempItems, eventName: this.toolbarAction };
            fListBox.trigger('actionBegin', localDataArgs);
            if (localDataArgs.cancel) {
                return;
            }
            const rLiCollection = [];
            dataLiIdx.sort((n1, n2) => n1 - n2).reverse().forEach((i) => {
                rLiCollection.push(fliCollections.splice(i, 1)[0]);
            });
            fListBox.liCollections = fliCollections;
            if (index) {
                const toColl = tliCollections.splice(0, index);
                tListBox.liCollections = toColl.concat(rLiCollection.reverse()).concat(tliCollections);
            }
            else {
                tListBox.liCollections = tliCollections.concat(rLiCollection.reverse());
            }
            if (tListBox.listData.length === 0) {
                const noRecElem = tListBox.ulElement.childNodes[0];
                if (noRecElem) {
                    tListBox.ulElement.removeChild(noRecElem);
                }
            }
            dataIdx.sort((n1, n2) => n2 - n1).forEach((i) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                listData.splice(i, 1)[0];
            });
            sortIdx.sort((n1, n2) => n2 - n1).forEach((i) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                sortData.splice(i, 1)[0];
            });
            jsonIdx.slice().reverse().forEach((i) => {
                data.push(fListBox.jsonData.splice(i, 1)[0]);
            });
            if (isRefresh) {
                if (fListBox.fields.groupBy) {
                    let sourceElem = fListBox.renderItems(listData, fListBox.fields);
                    fListBox.updateListItems(sourceElem, fListBox.ulElement);
                }
                else {
                    elems.forEach((ele) => { detach(ele); });
                }
            }
            else {
                moveTo(fListBox.ulElement, tListBox.ulElement, idx, index);
                fListBox.trigger('actionComplete', { items: tempItems, eventName: this.toolbarAction });
            }
            if (tListBox.mainList.childElementCount !== tListBox.jsonData.length) {
                tListBox.mainList = tListBox.ulElement;
            }
            fListBox.updateMainList();
            const tJsonData = [].slice.call(tListBox.jsonData);
            tSortData = [].slice.call(tListBox.sortedData);
            this.selectNextList(elems, dataLiIdx, dataIdx, fListBox);
            if (isKey) {
                this.list.focus();
            }
            fListBox.listData = listData;
            fListBox.sortedData = sortData;
            index = (index) ? index : tListData.length;
            for (let i = tempItems.length - 1; i >= 0; i--) {
                tListData.splice(index, 0, tempItems[i]);
                tJsonData.splice(index, 0, tempItems[i]);
                tSortData.splice(index, 0, tempItems[i]);
            }
            tListBox.listData = tListData;
            tListBox.jsonData = tJsonData;
            tListBox.sortedData = tSortData;
            if (isRefresh) {
                let sourceElem = tListBox.renderItems(tListData, tListBox.fields);
                tListBox.updateListItems(sourceElem, tListBox.ulElement);
                tListBox.setSelection();
                fListBox.trigger('actionComplete', { items: tempItems, eventName: this.toolbarAction });
            }
            fListBox.updateSelectedOptions();
            if (fListBox.listData.length === 0) {
                fListBox.l10nUpdate();
            }
        }
        if (fListBox.value.length === 1 && fListBox.getSelectedItems().length) {
            fListBox.value[0] = fListBox.getFormattedValue(fListBox.getSelectedItems()[0].getAttribute('data-value'));
        }
    }
    selectNextList(elems, dataLiIdx, dataIdx, inst) {
        const childCnt = inst.ulElement.querySelectorAll('.e-list-item').length;
        let ele;
        let liIdx;
        let validIdx = -1;
        if (elems.length === 1 && childCnt && !inst.selectionSettings.showCheckbox) {
            liIdx = childCnt <= dataLiIdx[0] ? childCnt - 1 : dataLiIdx[0];
            ele = inst.ulElement.querySelectorAll('.e-list-item')[liIdx];
            validIdx = inst.getValidIndex(ele, liIdx, childCnt === dataIdx[0] ? 38 : 40);
            if (validIdx > -1) {
                (inst.ulElement.querySelectorAll('.e-list-item')[validIdx].classList.add(cssClass.selected));
            }
        }
    }
    moveAllItemTo() {
        this.moveAllData(this, this.getScopedListBox());
    }
    moveAllItemFrom() {
        this.moveAllData(this.getScopedListBox(), this);
    }
    moveAllData(fListBox, tListBox, isKey, index) {
        let listData = [].slice.call(tListBox.listData);
        const jsonData = [].slice.call(tListBox.jsonData);
        const isRefresh = tListBox.sortOrder !== 'None' || (tListBox.selectionSettings.showCheckbox !==
            fListBox.selectionSettings.showCheckbox) || tListBox.fields.groupBy || tListBox.itemTemplate || fListBox.itemTemplate;
        this.removeSelected(fListBox, fListBox.getSelectedItems());
        const tempItems = [].slice.call(fListBox.jsonData);
        const localDataArgs = { cancel: false, items: tempItems, eventName: this.toolbarAction };
        fListBox.trigger('actionBegin', localDataArgs);
        if (localDataArgs.cancel) {
            return;
        }
        if (tListBox.listData.length === 0) {
            const noRecElem = tListBox.ulElement.childNodes[0];
            if (noRecElem) {
                tListBox.ulElement.removeChild(noRecElem);
            }
        }
        if (isRefresh) {
            const noRecElem = fListBox.ulElement.childNodes[0];
            if (noRecElem) {
                fListBox.ulElement.removeChild(noRecElem);
            }
        }
        moveTo(fListBox.ulElement, tListBox.ulElement, 
        // eslint-disable-next-line prefer-spread
        Array.apply(null, { length: fListBox.ulElement.childElementCount }).map(Number.call, Number), index);
        this.trigger('actionComplete', { items: tempItems, eventName: this.toolbarAction });
        if (isKey) {
            this.list.focus();
        }
        index = (index) ? index : listData.length;
        for (let i = 0; i < fListBox.listData.length; i++) {
            listData.splice(index + i, 0, fListBox.listData[i]);
        }
        for (let i = 0; i < fListBox.jsonData.length; i++) {
            jsonData.splice(index + i, 0, fListBox.jsonData[i]);
        }
        const fliCollections = [].slice.call(fListBox.liCollections);
        const tliCollections = [].slice.call(tListBox.liCollections);
        fListBox.liCollections = [];
        if (index) {
            const toColl = tliCollections.splice(0, index);
            tListBox.liCollections = toColl.concat(fliCollections).concat(tliCollections);
        }
        else {
            tListBox.liCollections = tliCollections.concat(fliCollections);
        }
        fListBox.value = [];
        listData = listData
            .filter((data) => data.isHeader !== true);
        tListBox.listData = listData;
        tListBox.jsonData = jsonData;
        if (this.listData.length == this.jsonData.length) {
            fListBox.listData = fListBox.sortedData = fListBox.jsonData = [];
        }
        else if (this.allowFiltering) {
            for (let i = 0; i < fListBox.listData.length; i++) {
                for (let j = 0; j < fListBox.jsonData.length; j++) {
                    if (fListBox.listData[i] === fListBox.jsonData[j]) {
                        fListBox.jsonData.splice(j, 1);
                    }
                }
            }
            fListBox.listData = fListBox.sortedData = [];
        }
        if (isRefresh) {
            let sourceElem = tListBox.renderItems(listData, tListBox.fields);
            tListBox.updateListItems(sourceElem, tListBox.ulElement);
            this.trigger('actionComplete', { items: tempItems, eventName: this.toolbarAction });
        }
        else {
            tListBox.sortedData = listData;
        }
        fListBox.updateSelectedOptions();
        if (fListBox.listData.length === 0) {
            fListBox.l10nUpdate();
        }
    }
    changeData(fromIdx, toIdx, jsonToIdx, ele) {
        const listData = [].slice.call(this.listData);
        const jsonData = [].slice.call(this.jsonData);
        const sortData = [].slice.call(this.sortedData);
        const jsonIdx = Array.prototype.indexOf.call(this.jsonData, this.getDataByElems([ele])[0]);
        const sortIdx = Array.prototype.indexOf.call(this.sortedData, this.getDataByElems([ele])[0]);
        const liColl = [].slice.call(this.liCollections);
        listData.splice(toIdx, 0, listData.splice(fromIdx, 1)[0]);
        jsonData.splice(jsonToIdx, 0, jsonData.splice(jsonIdx, 1)[0]);
        sortData.splice(toIdx, 0, sortData.splice(sortIdx, 1)[0]);
        liColl.splice(toIdx, 0, liColl.splice(fromIdx, 1)[0]);
        this.listData = listData;
        this.jsonData = jsonData;
        this.liCollections = liColl;
        this.sortedData = sortData;
    }
    getSelectedItems() {
        let ele = [];
        if (this.selectionSettings.showCheckbox) {
            [].slice.call(this.ulElement.getElementsByClassName('e-check')).forEach((cbox) => {
                ele.push(closest(cbox, '.' + 'e-list-item'));
            });
        }
        else {
            ele = [].slice.call(this.ulElement.getElementsByClassName(cssClass.selected));
        }
        return ele;
    }
    getScopedListBox() {
        let listObj;
        if (this.scope) {
            [].slice.call(document.querySelectorAll(this.scope)).forEach((ele) => {
                if (getComponent(ele, this.getModuleName())) {
                    listObj = getComponent(ele, this.getModuleName());
                }
            });
        }
        return listObj;
    }
    getGrabbedItems() {
        for (let i = 0; i < this.value.length; i++) {
            if (this.value[i] === this.dragValue) {
                const liColl = this.list.querySelectorAll('[aria-selected="true"]');
                for (let i = 0; i < liColl.length; i++) {
                    liColl[i].classList.add('e-grabbed');
                }
                break;
            }
        }
        const elems = Array.prototype.slice.call(this.element.querySelectorAll('.e-grabbed'));
        return elems;
    }
    getDragArgs(args, isDragEnd) {
        let elems = this.getGrabbedItems();
        if (elems.length) {
            if (isDragEnd) {
                elems.push(args.target);
            }
        }
        else {
            elems = [args.target];
        }
        return { elements: elems, items: this.getDataByElems(elems) };
    }
    onKeyDown(e) {
        this.keyDownHandler(e);
        e.stopPropagation();
    }
    keyDownHandler(e) {
        if ([32, 35, 36, 37, 38, 39, 40, 65].indexOf(e.keyCode) > -1 && !this.allowFiltering) {
            if (e.target && e.target.className.indexOf('e-edit-template') > -1) {
                return;
            }
            e.preventDefault();
            if (e.keyCode === 32 && this.ulElement.children.length) {
                this.selectHandler({
                    target: this.ulElement.getElementsByClassName('e-focused')[0],
                    ctrlKey: e.ctrlKey, shiftKey: e.shiftKey
                });
            }
            else if (e.keyCode === 65 && e.ctrlKey) {
                this.selectAll();
            }
            else if ((e.keyCode === 38 || e.keyCode === 40) && e.ctrlKey && e.shiftKey) {
                this.moveUpDown(e.keyCode === 38 ? true : false, true);
            }
            else if ((this.toolbarSettings.items.length || this.tBListBox) && (e.keyCode === 39 || e.keyCode === 37) && e.ctrlKey) {
                const listObj = this.tBListBox || this.getScopedListBox();
                if (e.keyCode === 39) {
                    if (e.shiftKey) {
                        this.moveAllData(this, listObj, true);
                    }
                    else {
                        this.moveData(this, listObj, true);
                    }
                }
                else {
                    if (e.shiftKey) {
                        this.moveAllData(listObj, this, true);
                    }
                    else {
                        this.moveData(listObj, this, true);
                    }
                }
            }
            else if (e.keyCode !== 37 && e.keyCode !== 39 && e.code !== "KeyA") {
                this.upDownKeyHandler(e);
            }
        }
        else if (this.allowFiltering) {
            if (e.keyCode === 40 || e.keyCode === 38) {
                this.upDownKeyHandler(e);
            }
        }
    }
    upDownKeyHandler(e) {
        const ul = this.ulElement;
        const defaultIdx = (e.keyCode === 40 || e.keyCode === 36) ? 0 : ul.childElementCount - 1;
        let fliIdx = defaultIdx;
        const fli = ul.getElementsByClassName('e-focused')[0] || ul.getElementsByClassName(cssClass.selected)[0];
        if (fli) {
            if (e.keyCode !== 35 && e.keyCode !== 36) {
                fliIdx = Array.prototype.indexOf.call(ul.children, fli);
                if (e.keyCode === 40) {
                    fliIdx++;
                }
                else {
                    fliIdx--;
                }
                if (fliIdx < 0 || fliIdx > ul.childElementCount - 1) {
                    return;
                }
            }
            removeClass([fli], 'e-focused');
        }
        const cli = ul.children[fliIdx];
        if (cli) {
            fliIdx = this.getValidIndex(cli, fliIdx, e.keyCode);
            if (fliIdx === -1) {
                addClass([fli], 'e-focused');
                return;
            }
            ul.children[fliIdx].focus();
            ul.children[fliIdx].classList.add('e-focused');
            if (!e.ctrlKey || !this.selectionSettings.showCheckbox && e.shiftKey && (e.keyCode === 36 || e.keyCode === 35)) {
                this.selectHandler({ target: ul.children[fliIdx], ctrlKey: e.ctrlKey, shiftKey: e.shiftKey }, true);
            }
            if (this.selectionSettings.showCheckbox && e.ctrlKey && e.shiftKey && (e.keyCode === 36 || e.keyCode === 35)) {
                let selectedidx = Array.prototype.indexOf.call(ul.children, fli);
                let sidx = e.code === "Home" ? 0 : selectedidx;
                let eidx = e.code === "Home" ? selectedidx : ul.children.length - 1;
                for (let i = sidx; i <= eidx; i++) {
                    const item = ul.children[i];
                    this.notify('updatelist', { li: item, e: {
                            target: this.ulElement.getElementsByClassName('e-focused')[0],
                            ctrlKey: e.ctrlKey, shiftKey: e.shiftKey
                        }, module: 'listbox' });
                }
            }
        }
    }
    KeyUp(e) {
        const char = String.fromCharCode(e.keyCode);
        const isWordCharacter = char.match(/\w/);
        if (!isNullOrUndefined(isWordCharacter)) {
            this.isValidKey = true;
        }
        this.isValidKey = (e.keyCode === 8) || (e.keyCode === 46) || this.isValidKey;
        if (this.isValidKey) {
            this.isValidKey = false;
            switch (e.keyCode) {
                default:
                    if (this.allowFiltering) {
                        const eventArgsData = {
                            preventDefaultAction: false,
                            text: this.targetElement(),
                            updateData: (dataSource, query, fields) => {
                                if (eventArgsData.cancel) {
                                    return;
                                }
                                this.isFiltered = true;
                                this.remoteFilterAction = true;
                                this.dataUpdater(dataSource, query, fields);
                            },
                            event: e,
                            cancel: false
                        };
                        this.trigger('filtering', eventArgsData, (args) => {
                            this.isDataFetched = false;
                            if (args.cancel || (this.filterInput.value !== '' && this.isFiltered)) {
                                return;
                            }
                            if (!args.cancel && !this.isCustomFiltering && !args.preventDefaultAction) {
                                this.inputString = this.filterInput.value;
                                this.filteringAction(this.jsonData, new Query(), this.fields);
                            }
                            if (!this.isFiltered && !this.isCustomFiltering && !args.preventDefaultAction) {
                                this.dataUpdater(this.jsonData, new Query(), this.fields);
                            }
                        });
                    }
            }
        }
    }
    /**
     * To filter the data from given data source by using query.
     *
     * @param  {Object[] | DataManager } dataSource - Set the data source to filter.
     * @param  {Query} query - Specify the query to filter the data.
     * @param  {FieldSettingsModel} fields - Specify the fields to map the column in the data table.
     * @returns {void}.
     */
    filter(dataSource, query, fields) {
        this.isCustomFiltering = true;
        this.filteringAction(dataSource, query, fields);
    }
    filteringAction(dataSource, query, fields) {
        this.resetList(dataSource, fields, query);
    }
    targetElement() {
        this.targetInputElement = this.list.getElementsByClassName('e-input-filter')[0];
        return this.targetInputElement.value;
    }
    dataUpdater(dataSource, query, fields) {
        this.isDataFetched = false;
        const backCommand = true;
        if (this.targetElement().trim() === '') {
            const list = this.mainList.cloneNode ? this.mainList.cloneNode(true) : this.mainList;
            if (backCommand) {
                this.remoteCustomValue = false;
                this.onActionComplete(list, this.jsonData);
                this.notify('reOrder', { module: 'CheckBoxSelection', enable: this.selectionSettings.showCheckbox, e: this });
            }
        }
        else {
            this.resetList(dataSource, fields, query);
        }
    }
    focusOutHandler() {
        const ele = this.list.getElementsByClassName('e-focused')[0];
        if (ele) {
            ele.classList.remove('e-focused');
        }
        if (this.allowFiltering) {
            this.refreshClearIcon();
        }
    }
    getValidIndex(cli, index, keyCode) {
        const cul = this.ulElement;
        if (cli.classList.contains('e-disabled') || cli.classList.contains(cssClass.group)) {
            if (keyCode === 40 || keyCode === 36) {
                index++;
            }
            else {
                index--;
            }
        }
        if (index < 0 || index === cul.childElementCount) {
            return -1;
        }
        cli = cul.querySelectorAll('.e-list-item')[index];
        if (cli.classList.contains('e-disabled') || cli.classList.contains(cssClass.group)) {
            index = this.getValidIndex(cli, index, keyCode);
        }
        return index;
    }
    updateSelectedOptions() {
        const selectedOptions = [];
        const values = [];
        extend(values, this.value);
        this.getSelectedItems().forEach((ele) => {
            if (!ele.classList.contains('e-grabbed')) {
                selectedOptions.push(this.getFormattedValue(ele.getAttribute('data-value')));
            }
        });
        if (this.mainList.childElementCount === this.ulElement.childElementCount) {
            if (this.allowFiltering && this.selectionSettings.showCheckbox) {
                for (let i = 0; i < selectedOptions.length; i++) {
                    if (values.indexOf(selectedOptions[i]) > -1) {
                        continue;
                    }
                    else {
                        values.push(selectedOptions[i]);
                    }
                }
                this.setProperties({ value: values }, true);
            }
            else {
                this.setProperties({ value: selectedOptions }, true);
            }
        }
        this.updateSelectTag();
        this.updateToolBarState();
        if (this.tBListBox) {
            this.tBListBox.updateToolBarState();
        }
    }
    clearSelection(values = this.value) {
        if (this.selectionSettings.showCheckbox) {
            let dvalue;
            this.getSelectedItems().forEach((li) => {
                dvalue = this.getFormattedValue(li.getAttribute('data-value'));
                if (values.indexOf(dvalue) < 0) {
                    li.getElementsByClassName('e-check')[0].classList.remove('e-check');
                    li.getElementsByClassName('e-checkbox-wrapper')[0].removeAttribute('aria-checked');
                    li.removeAttribute('aria-selected');
                }
            });
        }
    }
    setSelection(values = this.value, isSelect = true, isText = false) {
        let li;
        let liselect;
        if (values) {
            values.forEach((value) => {
                let text;
                if (isText) {
                    text = this.getValueByText(value);
                }
                else {
                    text = value;
                }
                li = this.list.querySelector('[data-value="' + text + '"]');
                if (li) {
                    if (this.selectionSettings.showCheckbox) {
                        liselect = li.getElementsByClassName('e-frame')[0].classList.contains('e-check');
                    }
                    else {
                        liselect = li.classList.contains('e-selected');
                    }
                    if (!isSelect && liselect || isSelect && !liselect && li) {
                        if (this.selectionSettings.showCheckbox) {
                            this.notify('updatelist', { li: li, module: 'listbox' });
                        }
                        else {
                            if (isSelect) {
                                li.classList.add(cssClass.selected);
                                li.setAttribute('aria-selected', 'true');
                            }
                            else {
                                li.classList.remove(cssClass.selected);
                                li.removeAttribute('aria-selected');
                            }
                        }
                    }
                }
            });
        }
        this.updateSelectTag();
    }
    updateSelectTag() {
        const ele = this.getSelectTag();
        let innerHTML = '';
        ele.innerHTML = '';
        if (this.value) {
            for (let i = 0, len = this.value.length; i < len; i++) {
                innerHTML += '<option selected value="' + this.value[i] + '"></option>';
            }
            ele.innerHTML += innerHTML;
        }
        this.checkSelectAll();
    }
    checkDisabledState(inst) {
        return inst.ulElement.querySelectorAll('.' + cssClass.li).length === 0;
    }
    updateToolBarState() {
        if (this.toolbarSettings.items.length) {
            const listObj = this.getScopedListBox();
            const wrap = this.list.parentElement.getElementsByClassName('e-listbox-tool')[0];
            this.toolbarSettings.items.forEach((value) => {
                const btn = wrap.querySelector('[data-value="' + value + '"]');
                switch (value) {
                    case 'moveAllTo':
                        btn.disabled = this.checkDisabledState(this);
                        break;
                    case 'moveAllFrom':
                        btn.disabled = this.checkDisabledState(listObj);
                        break;
                    case 'moveFrom':
                        btn.disabled = listObj.value && listObj.value.length ? false : true;
                        break;
                    case 'moveUp':
                        btn.disabled = this.value && this.value.length
                            && !this.isSelected(this.ulElement.children[0]) ? false : true;
                        break;
                    case 'moveDown':
                        btn.disabled = this.value && this.value.length
                            && !this.isSelected(this.ulElement.children[this.ulElement.childElementCount - 1]) ? false : true;
                        break;
                    default:
                        btn.disabled = this.value && this.value.length ? false : true;
                        break;
                }
            });
        }
    }
    setCheckboxPosition() {
        const listWrap = this.list;
        if (!this.initLoad && this.selectionSettings.checkboxPosition === 'Left') {
            listWrap.classList.remove('e-right');
        }
        if (this.selectionSettings.checkboxPosition === 'Right') {
            listWrap.classList.add('e-right');
        }
    }
    showCheckbox(showCheckbox) {
        let index = 0;
        const liColl = this.list.lastElementChild.querySelectorAll('li');
        const liCollLen = this.list.lastElementChild.getElementsByClassName('e-list-item').length;
        if (showCheckbox) {
            this.ulElement = this.renderItems(this.listData, this.fields);
            this.mainList = this.ulElement;
            this.list.removeChild(this.list.getElementsByTagName('ul')[0]);
            this.list.appendChild(this.ulElement);
            if (this.selectionSettings.showSelectAll && !this.list.getElementsByClassName('e-selectall-parent')[0]) {
                const l10nShow = new L10n(this.getModuleName(), { selectAllText: 'Select All', unSelectAllText: 'Unselect All' }, this.locale);
                this.showSelectAll = true;
                this.selectAllText = l10nShow.getConstant('selectAllText');
                this.unSelectAllText = l10nShow.getConstant('unSelectAllText');
                this.popupWrapper = this.list;
                this.checkBoxSelectionModule.checkAllParent = null;
                this.notify('selectAll', {});
                this.checkSelectAll();
            }
        }
        else {
            if (this.list.getElementsByClassName('e-selectall-parent')[0]) {
                this.list.removeChild(this.list.getElementsByClassName('e-selectall-parent')[0]);
            }
            for (index; index < liCollLen; index++) {
                if (liColl[index].classList.contains('e-list-item')) {
                    liColl[index].removeChild(liColl[index].getElementsByClassName('e-checkbox-wrapper')[0]);
                }
                if (liColl[index].hasAttribute('aria-selected')) {
                    liColl[index].removeAttribute('aria-selected');
                }
            }
            this.mainList = this.ulElement;
        }
        this.value = [];
    }
    isSelected(ele) {
        if (!isNullOrUndefined(ele)) {
            return ele.classList.contains(cssClass.selected) || ele.querySelector('.e-check') !== null;
        }
        else {
            return false;
        }
    }
    getSelectTag() {
        return this.list.getElementsByClassName('e-hidden-select')[0];
    }
    getToolElem() {
        return this.list.parentElement.getElementsByClassName('e-listbox-tool')[0];
    }
    formResetHandler() {
        this.value = this.initialSelectedOptions;
    }
    /**
     * Return the module name.
     *
     * @private
     * @returns {string} - Module name
     */
    getModuleName() {
        return 'listbox';
    }
    /**
     * Get the properties to be maintained in the persisted state.
     *
     * @returns {string} - Persist data
     */
    getPersistData() {
        return this.addOnPersist(['value']);
    }
    getLocaleName() {
        return 'listbox';
    }
    destroy() {
        this.unwireEvents();
        if (this.element.tagName === 'EJS-LISTBOX') {
            this.element.innerHTML = '';
        }
        else {
            this.element.style.display = 'inline-block';
            if (this.toolbarSettings.items.length) {
                this.list.parentElement.parentElement.insertBefore(this.list, this.list.parentElement);
                detach(this.list.nextElementSibling);
            }
            this.list.parentElement.insertBefore(this.element, this.list);
        }
        super.destroy();
        this.enableRtlElements = [];
        this.liCollections = null;
        this.list = null;
        this.ulElement = null;
        this.mainList = null;
        this.spinner = null;
        this.rippleFun = null;
        if (this.itemTemplate) {
            this.clearTemplate();
        }
    }
    /**
     * Called internally if any of the property value changed.
     *
     * @param {ListBoxModel} newProp - Specifies the new properties.
     * @param {ListBoxModel} oldProp - Specifies the old properties.
     * @returns {void}
     * @private
     */
    onPropertyChanged(newProp, oldProp) {
        const wrap = this.toolbarSettings.items.length ? this.list.parentElement : this.list;
        super.onPropertyChanged(newProp, oldProp);
        this.setUpdateInitial(['fields', 'query', 'dataSource'], newProp);
        for (const prop of Object.keys(newProp)) {
            switch (prop) {
                case 'cssClass':
                    if (oldProp.cssClass) {
                        removeClass([wrap], oldProp.cssClass.split(' '));
                    }
                    if (newProp.cssClass) {
                        addClass([wrap], newProp.cssClass.split(' '));
                    }
                    break;
                case 'enableRtl':
                    if (newProp.enableRtl) {
                        this.list.classList.add('e-rtl');
                    }
                    else {
                        this.list.classList.remove('e-rtl');
                    }
                    break;
                case 'value':
                    removeClass(this.list.querySelectorAll('.' + cssClass.selected), cssClass.selected);
                    this.clearSelection(this.value);
                    this.setSelection();
                    break;
                case 'height':
                    this.setHeight();
                    break;
                case 'enabled':
                    this.setEnable();
                    break;
                case 'allowDragAndDrop':
                    if (newProp.allowDragAndDrop) {
                        this.initDraggable();
                    }
                    else {
                        getComponent(this.ulElement, 'sortable').destroy();
                    }
                    break;
                case 'allowFiltering':
                    if (this.allowFiltering) {
                        this.setFiltering();
                    }
                    else {
                        this.list.removeChild(this.list.getElementsByClassName('e-filter-parent')[0]);
                        this.filterParent = null;
                        removeClass([this.list], 'e-filter-list');
                    }
                    break;
                case 'filterBarPlaceholder':
                    if (this.allowFiltering) {
                        if (this.filterInput) {
                            Input.setPlaceholder(newProp.filterBarPlaceholder, this.filterInput);
                        }
                    }
                    break;
                case 'scope':
                    if (this.allowDragAndDrop) {
                        getComponent(this.ulElement, 'sortable').scope = newProp.scope;
                    }
                    if (this.toolbarSettings.items.length) {
                        if (oldProp.scope) {
                            getComponent(document.querySelector(oldProp.scope), this.getModuleName())
                                .tBListBox = null;
                        }
                        if (newProp.scope) {
                            getComponent(document.querySelector(newProp.scope), this.getModuleName())
                                .tBListBox = this;
                        }
                    }
                    break;
                case 'toolbarSettings': {
                    let ele;
                    const pos = newProp.toolbarSettings.position;
                    const toolElem = this.getToolElem();
                    if (pos) {
                        removeClass([wrap], ['e-right', 'e-left']);
                        wrap.classList.add('e-' + pos.toLowerCase());
                        if (pos === 'Left') {
                            wrap.insertBefore(toolElem, this.list);
                        }
                        else {
                            wrap.appendChild(toolElem);
                        }
                    }
                    if (newProp.toolbarSettings.items) {
                        if (oldProp.toolbarSettings && oldProp.toolbarSettings.items.length) {
                            ele = this.list.parentElement;
                            ele.parentElement.insertBefore(this.list, ele);
                            detach(ele);
                        }
                        this.initToolbarAndStyles();
                        this.wireToolbarEvent();
                    }
                    break;
                }
                case 'selectionSettings': {
                    const showSelectAll = newProp.selectionSettings.showSelectAll;
                    const showCheckbox = newProp.selectionSettings.showCheckbox;
                    if (!isNullOrUndefined(showSelectAll)) {
                        this.showSelectAll = showSelectAll;
                        if (this.showSelectAll) {
                            const l10nSel = new L10n(this.getModuleName(), { selectAllText: 'Select All', unSelectAllText: 'Unselect All' }, this.locale);
                            this.checkBoxSelectionModule.checkAllParent = null;
                            this.showSelectAll = true;
                            this.selectAllText = l10nSel.getConstant('selectAllText');
                            this.unSelectAllText = l10nSel.getConstant('selectAllText');
                            this.popupWrapper = this.list;
                        }
                        this.notify('selectAll', {});
                        this.checkSelectAll();
                    }
                    if (!isNullOrUndefined(showCheckbox)) {
                        this.showCheckbox(showCheckbox);
                    }
                    if (this.selectionSettings.showCheckbox) {
                        this.setCheckboxPosition();
                    }
                    break;
                }
                case 'dataSource':
                    this.isDataSourceUpdate = true;
                    this.jsonData = [].slice.call(this.dataSource);
                    break;
            }
        }
    }
};
__decorate([
    Property('')
], ListBox.prototype, "cssClass", void 0);
__decorate([
    Property([])
], ListBox.prototype, "value", void 0);
__decorate([
    Property('')
], ListBox.prototype, "height", void 0);
__decorate([
    Property(false)
], ListBox.prototype, "allowDragAndDrop", void 0);
__decorate([
    Property(1000)
], ListBox.prototype, "maximumSelectionLength", void 0);
__decorate([
    Property(false)
], ListBox.prototype, "allowFiltering", void 0);
__decorate([
    Property('')
], ListBox.prototype, "scope", void 0);
__decorate([
    Property(true)
], ListBox.prototype, "ignoreCase", void 0);
__decorate([
    Property(null)
], ListBox.prototype, "filterBarPlaceholder", void 0);
__decorate([
    Event()
], ListBox.prototype, "beforeItemRender", void 0);
__decorate([
    Event()
], ListBox.prototype, "filtering", void 0);
__decorate([
    Event()
], ListBox.prototype, "select", void 0);
__decorate([
    Event()
], ListBox.prototype, "change", void 0);
__decorate([
    Event()
], ListBox.prototype, "beforeDrop", void 0);
__decorate([
    Event()
], ListBox.prototype, "dragStart", void 0);
__decorate([
    Event()
], ListBox.prototype, "drag", void 0);
__decorate([
    Event()
], ListBox.prototype, "drop", void 0);
__decorate([
    Event()
], ListBox.prototype, "dataBound", void 0);
__decorate([
    Property(null)
], ListBox.prototype, "groupTemplate", void 0);
__decorate([
    Property('Request failed')
], ListBox.prototype, "actionFailureTemplate", void 0);
__decorate([
    Property(1000)
], ListBox.prototype, "zIndex", void 0);
__decorate([
    Property(false)
], ListBox.prototype, "ignoreAccent", void 0);
__decorate([
    Complex({}, ToolbarSettings)
], ListBox.prototype, "toolbarSettings", void 0);
__decorate([
    Complex({}, SelectionSettings)
], ListBox.prototype, "selectionSettings", void 0);
ListBox = ListBox_1 = __decorate([
    NotifyPropertyChanges
], ListBox);
export { ListBox };
const listBoxClasses = {
    backIcon: 'e-input-group-icon e-back-icon e-icons',
    filterBarClearIcon: 'e-input-group-icon e-clear-icon e-icons',
    filterInput: 'e-input-filter',
    filterParent: 'e-filter-parent',
    clearIcon: 'e-clear-icon'
};
